import { isNil } from 'ramda';
import React, { ReactElement, memo, useEffect, useState } from 'react';
import { slugify } from '@bridebook/toolbox/src';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import TabV2 from './tab-v2';
import componentStyles from './tabs-v2.style';

export interface ITabsItem {
  title: string | ReactElement<any>;
  disabled?: boolean;
  id: string;
}

interface IProps extends IStylingProps {
  activeTabIndex?: number; // explicitly activate tab by index
  fontSize?: number;
  justified?: boolean;
  onTabClick(item: ITabsItem, index: number): void;
  switchedOn?: boolean; // to have first tab active by default
  tabMargin?: number; // to set side margins of each tab
  tabPadding?: number;
  tabs: ITabsItem[];
}

const TabsV2Component = ({
  activeTabIndex,
  tabs,
  switchedOn,
  justified,
  tabMargin = 0,
  tabPadding = 24,
  fontSize,
  onTabClick,
  ...restProps
}: IProps) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex || 0);

  useEffect(() => {
    if (!isNil(activeTabIndex) && activeTabIndex !== activeIndex) {
      setActiveIndex(activeTabIndex);
    }
  }, [activeIndex, activeTabIndex]);

  const _onTabClick: IProps['onTabClick'] = (item, index) => {
    setActiveIndex(index);
    onTabClick(item, index);
  };

  const styles = componentStyles({ tabMargin, justified, tabsAmount: tabs.length });

  return (
    <Box style={styles.wrapper} {...restProps}>
      {tabs.map((item, index) => (
        <Box
          style={styles.tabWrapper(index === tabs.length - 1)}
          key={`tabs-tab-item-${slugify(item.id || item.title.toString())}`}
          onClick={() => _onTabClick(item, index)}>
          <TabV2
            active={index === activeIndex}
            item={item}
            switchedOn={switchedOn}
            justified={justified}
            fontSize={fontSize}
            tabPadding={tabPadding}
          />
        </Box>
      ))}
    </Box>
  );
};

export const TabsV2 = memo(TabsV2Component);
