import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';

interface IStyleProps {
  active: boolean;
  disabled: boolean;
  fontSize?: number;
  hover: boolean;
  justified?: boolean;
  tabPadding?: number;
}

interface IStyles {
  wrapper: FelaCSS;
}

const styles = ({ active, disabled, fontSize = 14 }: IStyleProps): IStyles => ({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
    position: 'relative',
    marginHorizontal: 1,
    paddingVertical: 2,
    paddingHorizontal: 1,
    fontDefault: fontSize,
    lineHeight: '20px',
    cursor: disabled || active ? 'default' : 'pointer',
    color: active ? colors.space : '#76808C',
    backgroundColor: active ? colors.white : '#F0F0F0',
    borderRadius: 20,
    transition: '0.2s ease-in-out',
  },
});

export default styles;
