import React, { FC } from 'react';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import hoverHoc, { WithHoverHOC } from '../../../utils/hover-hoc';
import componentStyles from './tab-v2.style';
import { ITabsItem } from './tabs-v2';

interface IProps extends IStylingProps {
  active: boolean;
  fontSize?: number;
  item: ITabsItem;
  justified?: boolean;
  switchedOn: boolean;
  tabPadding?: number;
}

const TabV2: FC<IProps> = ({
  item,
  active,
  onMouseEnter,
  onMouseLeave,
  switchedOn,
  hover,
  justified,
  fontSize,
  tabPadding,
  ...restProps
}: WithHoverHOC<IProps>) => {
  const { title, disabled } = item;
  const styles = componentStyles({
    hover,
    active,
    justified,
    disabled: Boolean(disabled),
    fontSize,
  });

  return (
    <Box
      as="a"
      style={styles.wrapper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...restProps}>
      {title}
    </Box>
  );
};

export default hoverHoc(TabV2);
