import { colors } from '../../../fela-themes';
import { FelaCSS } from '../../fela/flowtypes';

interface IStyles {
  wrapper: FelaCSS;
  tabWrapper: (last: boolean) => FelaCSS;
}

interface IStyleProps {
  tabMargin?: number; // to set side margins of each tab
  tabsAmount: number;
  justified?: boolean;
}

const styles = ({ tabMargin = 12, justified, tabsAmount }: IStyleProps): IStyles => {
  const justifiedWidth = `${100 / tabsAmount}%`;

  return {
    wrapper: {
      flexDirection: 'row',
      backgroundColor: colors.soft,
      paddingVertical: 1,
      borderRadius: '30px',
    },

    tabWrapper: (last: boolean): FelaCSS => ({
      flexShrink: 0,
      marginRight: last || justified ? '0' : `${tabMargin}px`,
      width: justifiedWidth,
      height: '100%',
    }),
  };
};

export default styles;
