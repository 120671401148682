import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Button } from '@bridebook/ui';
import { useToggleFilters } from 'components/search/hooks';
import {
  onSortChangeAnalytics,
  setFiltersTab,
  setSortingOption,
  updateFiltersCount,
} from 'lib/search/actions';
import { defaultSortValue } from 'lib/search/constants';
import { useSearchCountWithSelectedFilters } from 'lib/search/hooks/query/use-search-count';
import {
  areFiltersShown,
  getFiltersCount,
  getFiltersTab,
  getSearchRequestSort,
  getSortingOption,
} from 'lib/search/selectors';
import { ConnectedTypes, IApplicationState, IUrl } from 'lib/types';
import { useDispatch, useSelector } from 'lib/utils';
import FiltersContainer from '../filters-container/filters-container';
import componentStyles from './filter-mobile-form.style';
import FilterTopBar from './filter-top-bar';


const storeEnhancer = connect((state: IApplicationState) => ({
  filtersCount: getFiltersCount(state),
  isMobile: state.app.device.isMobile,
  filtersShown: areFiltersShown(state),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  location: IUrl;

  close(): void;
}

const FilterMobileForm = ({ location, filtersShown, close }: IProps) => {
  const { t } = useTranslation('search');
  const filtersTab = useSelector(getFiltersTab);
  const selectedSortValue = useSelector(getSortingOption);
  const sortValueFromRequest = useSelector(getSearchRequestSort);
  const dispatch = useDispatch();

  const toggleFilters = useToggleFilters({ location, filtersShown, sortBy: selectedSortValue });

  useEffect(() => {
    dispatch(setFiltersTab('filters'));
    dispatch(setSortingOption(sortValueFromRequest || defaultSortValue));
  }, [dispatch, sortValueFromRequest]);

  const handleSubmit = useCallback(() => {
    dispatch(updateFiltersCount());
    dispatch(onSortChangeAnalytics(selectedSortValue));
    toggleFilters();
  }, [dispatch, selectedSortValue, toggleFilters]);

  const styles = componentStyles();

  const totalResults = useSearchCountWithSelectedFilters();
  const ctaText = useMemo(
    () =>
      totalResults !== undefined
        ? t('viewNumberOfResults', { count: totalResults })
        : t('applyFilters'),
    [t, totalResults],
  );

  return (
    <Box style={styles.wrapper}>
      <FilterTopBar filtersTab={filtersTab} close={close} />
      <FiltersContainer />
      <Box style={styles.buttonWrap}>
        <Button text={ctaText} theme="primary" width="block" onClick={handleSubmit} />
      </Box>
    </Box>
  );
};

export default storeEnhancer(FilterMobileForm);
