import React, { Component, ComponentType } from 'react';

type IProps = {
  isMobile?: boolean;
  isTablet?: boolean;
};

export type WithHoverHOC<P> = {
  hover: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & P;

type State = {
  hover: boolean;
};

const hoverHoc = <OriginalProps extends object>(
  WrappedComponent: ComponentType<OriginalProps>,
): ComponentType<OriginalProps> =>
  class hoverStateTrigger extends Component<OriginalProps & IProps, State> {
    state = { hover: false };

    onMouseEnter = () => this.setState({ hover: true });
    onMouseLeave = () => this.setState({ hover: false });

    render() {
      const { isMobile, isTablet } = this.props;

      return isMobile || isTablet ? (
        <WrappedComponent {...this.props} />
      ) : (
        <WrappedComponent
          {...this.props}
          hover={this.state.hover}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      );
    }
  };

export default hoverHoc;
